.entrance {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 116px 5% 0 5%;
  overflow: hidden;
  font-family: Roboto;

  background-position: bottom;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;

  .entranceBg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    user-select: none;
  }

  .title {
    max-width: 800px;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    color: #000000;
    line-height: 60px;
    margin: 0 auto;
    position: relative;
  }

  .scratchBtn {
    min-width: 200px;
    padding: 18px 30px;
    font-size: 17px;
    font-weight: 600;
    border-radius: 30px;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    transition: all 0.3s;
    color: #FFFFFF;
    background-color: #FF822C;
    margin-top: 32px;
    position: relative;

    & > svg {
      margin: 0 10px;
    }

    & > svg path{
      fill: #ffffff;
      transition: all 0.3s;
    }

    &:hover {
      background: #e0640f;
    }
  }

  .entranceImgBox {
    max-width: 1250px;
    margin: 0 auto;
    user-select: none;
    .entranceImg {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
      object-fit: contain;
      position: relative;
      top: 4px;
    }
  }
}

@media all and (max-width: 520px) {
  .entrance {
    padding: 83px 5% 0 5%;

    .title {
      font-size: 26px;
      line-height: 34px;
    }

    .scratchBtn {
      margin-top: 18px;
      margin-bottom: 10px;
      height: 42px;
      line-height: 42px;
      padding: 0 25px;
      min-width: max-content;
      font-size: 15px;

      & > svg {
        width: 16px;
      }
    }
  }
}
